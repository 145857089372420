export const Url = {
    LOGIN_URL: 'login/',
    SIGNUP_UR: 'signup/',
    FORGET_PASSWORD_URL: 'forgot-password/',
    RESET_PASSWORD_URL: 'reset-password/',
    DEAL_URL: 'deals?last_min_booking=false',
    CREATE_DEAL: 'deals/',
    UPDATE_DEAL: 'deals/',
    LAST_MIN_BOOKING_LIST_URL: 'deals?last_min_booking=true',
    ACTIVATION_URL: 'activation/',
    UPDATE_URL: 'clinic/',
    DEAL_FILTER_URL: 'deals?',
    ME_URL: 'clinic',
    REVIEW: 'clinic/reviews?',
    CONTACT: 'contact_us/',
    RESEND_CODE: 'resend-code/',
    USER_LIST: 'bookings',
    USER_INFO: 'user/',
    ATTACH_USER: 'bookings/',
    BOOKING_STATUS: 'booking/',
    DASHBOARD: 'dashboard',
    CLINIC_EXSITENCE: 'clinic/existence',
};
