let sew = {
    translations: {
        gnizzel: 'Gnizzel',
        welcomeToGnizzel: 'Välkommen till Gnizzel.',
        welcomeToGnizzelDescription:
            'Vänligen registrera ditt konto och nå nya kunder genom att skapa nya affärer och sista minuten-tider.',
        clinicDetail: 'Klinikdetalj',
        isA: 'är en',
        forClinic:
            'för tandvårdskliniker. Gå med oss ​​och hitta nya patienter. Prova',
        marketPlace: 'marknadsplats',
        monthsFor: '3 månader',
        free: 'gratis.',
        noDealHeader: 'Du har inget erbjudande',
        noBookingHeader: 'Du har inte en sista minuten bokning än',
        noDealSubHeader: 'Skapa erbjudandet här',
        createDeal: 'Skapa Erbjudande',
        dealNotFound: 'Det gick inte att hitta erbjudandet för sökordet.',
        lastMinNotFound: 'Sista minsta erbjudande hittades inte för sökordet.',
        searchKeyword: 'Sökord:',
        deals: 'Erbjudandet',
        createNewBook: 'Skapa Ny Sista Minuten Bokning', // Skapa Ny Sista Minuten Bokning
        booking: 'Sista minuten bokninga',
        bookr: 'Sista minuten boknigar ',
        dealsDetail: 'Se dina erbjudanden här.',
        noBookingSubHeader: 'Skapa din sista min bokning',
        createBooking: 'Skapa',
        createLastMinCard: 'Skapa Sista Minuten Bokning',
        createNewDeal: 'Skapa Nytt Erbjudande',
        filter: 'Filterera',
        updateDeal: 'Uppdatera erbjudandet',
        dealName: 'Namn på erbjudandet',
        bookName: 'Bokningsnamn',
        dealAbout: 'Om erbjudandet',
        dealAboutPlace: 'Om affären Text...',
        dealPrice: 'Pris på erbjudandet',
        dealPricePlace: 'Pris på erbjudandet',
        dealBookingPlace: 'Pris på bokning',
        dealDiscountDeal: 'Rabatt på Deal (%)',
        dealOffer: 'Antal erbjudanden',
        dealNumb: 'Antal bokningar',
        dealPriceError: 'Endast siffror är tillåtna',
        dealNameError:
            'Endast alfbater är tillåtna och längden får inte överstiga 25 tecken',
        dealCountError: 'Endast siffror är tillåtna.',
        dealNumber: 'Antal erbjudanden',
        dealDuration: 'Tid för erbjudandet',
        dealEnd: 'Sluttid',
        dealStart: 'Starttid',
        dealCallClinic: 'Inga tidsbegränsningar',
        dealCreateButton: 'Skapa',
        dealPreviewButton: 'Förhandsvisning',
        dealUpdateButton: 'UPPDATERA',
        cardUpdate: 'Uppdatera',
        cardDisable: 'Inaktivera',
        cardExpired: 'Förfallen',
        cardEnable: 'Aktivera',
        perview: 'Förhandsgranska',
        previewAppointment: 'Affärens varaktighet',
        close: 'Stäng',
        logout: 'Logga ut',
        dealAboutBook: 'Om sista minuten bokning...',
        clinicSettings: 'Inställningar',
        cardDisableModalText: 'Är du säker på att erbjudandet ska inaktiveras?',
        cardEnableModalText: 'Är du säker på att du vill aktivera erbjudandet?',
        language: 'Språk',
        engLang: 'Engelsk',
        Email: 'E-post',
        Password: 'Lösenord',
        Welcome: 'Välkommen',
        Gnizzel: 'Gnizzel',
        In: 'In',
        Account: '',
        Forgot: 'Glömt ',
        dental: ' tandläkare',
        any: 'när',
        time: 'som helst ',
        anywhere: 'var some',
        by: ' helst genom att ',
        Joining: 'gå med',
        us: 'oss',
        getStarted: 'Välkommen',
        bookADemo: 'Boka En Demo',
        consultA: 'Kontakta en',
        anyTimeAnyWhere: ' när som helst, var som helst genom att',
        joiningUs: 'gå med oss',
        signUp: 'Bli medlem',
        clinicName: 'Namn på mottagningen',
        yourName: 'Ange ditt namn',
        fullName: 'Fullständiga namn',
        yourAddress: 'Din Adress',
        yourAddressPlaceholder: 'Vänligen ange din adress',
        postalCode: 'Post Nummer',
        postalCodePlaceholder: 'Vänligen ange postnummer',
        city: 'Post Ort',
        cityPlaceholder: 'Var god ange postadress',
        noOfPatient: 'Antal Patienter',
        homePage: 'Webbadresslänk Här (Valfritt)',
        homePagePlaceholer: 'Vänligen ange webbadress',
        alreadyAccount: 'Har redan ett konto?',
        forget: 'Glömt lösenord?',
        signIn: 'Logga In',
        forgetPara:
            'Fyll i din email. Du kommer få en email for att nollställa ditt lösenord',
        request: 'Skicka förfrågan',
        createNew: 'Skapa nytt lösenord',
        newPass:
            'Ditt nya lösenord måste skilja sig från det tidigare använda lösenordet.',
        enterPass: 'Nytt lösenord',
        confirmPass: 'Bekräfta lösenord',
        passReg: 'Måste bestå av minst 8 tecken',
        emailReg: 'Ogiltigt format',
        backLogin: 'Tillbaka till Logga in',
        updatePass: 'Uppdatera  lösenord',
        lastMin: 'Sista Minuten Bokning',
        lastMinNav: 'Sista Minuten',
        Deals: 'Erbjudanden',
        Dashboard: 'Hem',
        rev: 'Omdömen',
        SignUppp: 'Bli Medlem',
        Sig: 'Logga in',
        sewLang: 'Svenska',
        clicName: 'Klinikens Namn',
        clicNamePlaceholder: 'Var god ange klinikens namn',
        si: 'Bli Medlem',
        contactUs: 'Kontakta oss',
        home: 'Hem',
        clickUpload: `Klicka för att ladda upp eller dra och släpp SVG, PNG, JPG eller GIF-filer (maximal storlek 2 MB).`,
        attachUser: 'Lägg Till Användare',
        noReview: 'Det finns inga recensioner ännu',
        swe: 'Svenska',
        dummy: 'Hitta nya patienter och få dina gamla kunder att besöka dig oftare. Lägg upp ett erbjudande och lägg upp sista minuten möten på ett enkelt sätt.',
        // dummy: 'Gnizzel är en marknadsplats för tandläkare mottagningar. Var med och gör er mer tillgängliga. Hitta nya patienter genom att synas på Gnizzels marknadsplats. Vi hjälper er öka synligheten och att få in fler patienter.',
        admin: 'Administration',
        cutomerReview: 'Kundrecension',
        clientSay: 'Vad kunden säger om våra kliniktjänster.',
        needHelp: 'Behövs hjälp?',
        Sign: 'Logga In',
        yesSure: ' Ja, Jag är säker',
        cancel: 'Avbryt',
        customerReview: 'Kundrecension',
        NoReviewsAvailable: 'Inga recensioner tillgängliga',
        goback: 'Gå tillbaka',
        subAttach: 'Ange användar-ID för att lägga till användare.',
        phoneNumber: 'Telefonnummer',
        phoneNumberPlaceholder: 'Vänligen ange telefonnummer',
        user: 'Ange användar-id ',
        userId: 'Ange användarkonto-ID',
        fetchUser: 'Hämta Användare',
        iD: 'Användarkonto-ID',
        dealTen: 'Erbjudanden Tillgängliga',
        userList: 'Användarlistor',
        feelFree:
            'Kontakta oss gärna så återkommer vi till dig så fort vi kan.',
        enterClinic: 'Ange klinikens namn',
        enterEmail: 'Ange Din E-postadress',
        des: 'Beskrivning',
        enterText: 'Ange Text Här...',
        submit: 'Skicka in nu',
        userInfo: 'användar information',
        firstName: 'Förnamn',
        surName: 'Efternamn',
        useDeal: 'Använd Deal',
        user_id: 'ANVÄNDAR ID',
        updateBoooking: 'Uppdatera sista minuten-bokning',
        update: 'Uppdatering',
        dealNumberBooking: 'Antal bokningar',
        cardDisableModalTextBook:
            'Är du säker på att du vill inaktivera denna sista minsta bokning?',
        invalidformat: 'Ogiltigt format',
        previewLastMin: 'Förhandsgranska',
        nameText: 'NAMN',
        phoneText: 'TELEFONNUMMER',
        statusText: 'STATUS',
        completed: 'Avslutad',
        inProgress: 'Pågående',
        canceled: 'Inställt',
        updateBooking: 'Uppdatera Sista Minuten Bokning',
        noName: 'Ingen namn',
        noText: 'Ingen text',
        pleaseSelectTime: 'Vänligen Välj Tid',
        noCancel: 'Nej, Avbryt',
        nameError: 'Endast alfabet är tillåtna',
        userInvalidId: 'Användar-ID är inte giltigt.',
        alphaNumericError: 'Endast alfanumeriska är tillåtna',
        numberError: 'Endast siffror är tillåtna',
        hyperLinkError:
            'Ange en giltig webbadress (t.ex. https://example.com/).',
        passwordError:
            'Måste innehålla 8 tecken, en stor bokstav, en liten bokstav, en siffra och ett specialtecken.',
        phoneNumberError: 'Endast siffror är tillåtna',
        emailError: 'Ange giltig e-postadress',
        hi: 'Hej',
        welcomeBack: 'Välkommen Tillbaka',
        clinicSummary: 'Sammanfattning Av Kliniken',
        clinicStep: 'Kliniksteg',
        freqAsk: 'Vanliga Frågor',
        forgotPass: 'Glömt ditt lösenord',
        loginSuccess: ' Logga in framgångsrikt',
        verify: 'Verifiera Email',
        resend: 'Återsänd koden',
        verifyCode: 'Verifiera kod',
        faq: 'Vad är Gnizzel?',
        secondFaq: 'Hur ändrar jag information om min klinik?',
        thirdFaq: 'Vad är ett erbjudande?',
        forthFaq: 'Hur skapar jag ett erbjudande?',
        fifthFaq: 'Hur gör jag ändringar i ett aktivit erbjudande?',
        sixFaq: 'Hur kan  jag få mina erbjudanden en begränsad tid?',
        sevenFaq:
            'Jag vill bara begränsa mitt erbjudande till ett visst antal användare.',
        eiFaq: 'Vad är en "Sista Minuten" bokning?',
        nineFaq: 'Hur skapar jag "Sista Minuten" bokning?',
        tenFaq: 'Hur gör jag ändringar i en befintlig "Sista Minuten" bokning?',
        elevenFaq: 'Kan jag skapa många sista minuten-erbjudanden?',
        twelveFaq:
            'Hur laddar jag upp min logotyp till mitt erbjudande eller till "Sista minuten"?',
        thirteenFaq:
            'Hur många "Deals" eller "Sista Minuten" bokningar kan jag ha samtidigt?',
        fourteenFaq:
            'Hur kan jag ta bort ett "Erbjudande" eller "Sista Minuten" bokning?',
        fifteenFaq:
            'Hur markerar jag en affär eller sista minuten så att den har använts?',
        sixteenFaq: 'Vad är kontoanvändare-ID?',
        seventeenFaq: 'Var anger jag kontots användar-ID?',
        eighteenFaq:
            'Jag har rabatterat pris på mitt erbjudande, Hur kan jag visa det för användaren/patienten?',
        nineteenFaq:
            'Hur ser jag till att mina patienter kan se mina erbjudanden och sista minuten tider?',
        twentyFaq: 'Vad är omdömen och hur fungerar det?',
        ansFaq: 'Gnizzel är en marknadsplats för tandläkare mottagningar för att hitta nya patienter. Mottagningen kan erbjuda erbjudanden och tillgängliga tider inklusive sista minuten tider.',
        ansTwo: 'Gå till inställningar som du hittar i rullgardinsmenyn till vänster.',
        ansThree:
            'Med ett erbjuande så kan mottagningen ha olika kampanjer för att logga nya eller existerande patienter.',
        ansFour:
            'Klicka på erbjudanden längst upp på meny fältet. Fyll i informationen och skapa erbjudandet. Användaren kommer genast att se på mobilappen.',
        ansFive:
            'Välj erbjudandet du vill ändra på erbjudandensidan. När du har gjort ändringar klickar du bara på "spara"-knappen.',
        ansSix: 'När du skapar affären kan du välja om din affär behöver vara på begränsad tid eller endast på ett begränsat antal erbjudanden. Du kan ställa in den begränsade tiden genom att använda i kalendern.',
        ansSeven:
            'Lägg till antal erbjudanden i rutan "antal erbjudanden" och markera rutan "inga tidsbegränsningar".',
        ansEight:
            'När en klinik har tillgänglighet kan de visa detta i Gnizzel genom att använda funktionen "Sista Minuten" bokning.',
        ansNine:
            'Klicka på "Sista-minuten" bokningar på menyn. Fyll i uppgifterna och skapa bokningen. Användaren kommer genast att se på mobilappen.',
        ansTen: 'Välj "Sista minuten-bokningar" som du vill ändra på sidan "Sista-minuten-bokningar". När du har gjort ändringar klickar du bara på "spara"-knappen.',
        ansEleven:
            'Ja, vi har lagt till ett fält när vi skapar sista minuten-erbjudande. Du kan skapa upp till 10 erbjudanden samtidigt genom att lägga till antalet erbjudanden du vill skapa. Istället för att skapa ett erbjudande åt gången, kommer detta att hjälpa dig att skapa många erbjudanden samtidigt om du har många olika tider tillgängliga under dagen eller veckan.',
        ansTwelve:
            'Gå till inställningar som du hittar i rullgardinsmenyn till vänster. Under inställningar kommer du att kunna ladda upp din logotyp som kommer att dyka upp på alla dina erbjudanden.',
        ansThirteen:
            'Du kan ha så många "Deals" och "Last Miinute"-bokningar som du vill. Vi har inga begränsningar.',
        ansFourteen:
            'Du kan inte ta bort en "Deal" eller "Sista Minute"-bokning men du kan inaktivera en deal. Det betyder att affären inte är aktiv och inte kommer att visas i mobilappen.',
        ansFifteen:
            'På sidan "Deal" och "Sista minuten" finns ett knappanrop "Lägg till användare". När du klickar på knappen kommer du att kunna lägga till kontoanvändar-ID för den som ringer på fältet. Genom att lägga till användaren till "Deal" eller "Sista minuten" kommer erbjudandet att subtraheras från numret du angav när du skapade erbjudandena.',
        ansSixteen:
            'Kontoanvändar-ID är ett unikt nummer som varje användare eller patient får från Gnizzel. Användaren kan hitta ID:t överst i mobilappen.',
        ansSeventeen:
            'På sidan "Deal" och "Sista minuten" finns ett knappanrop "Lägg till användare". När du klickar på knappen kommer du att kunna lägga till kontoanvändar-ID för den som ringer på fältet. Genom att lägga till användaren till "Deal" eller "Sista minuten" kommer erbjudandet att subtraheras från numret du angav när du skapade erbjudandena.',
        ansEighteen:
            'Vi har lagt till ett fält där du kan skriva ditt originalpris och rabattprocent när du skapar "Deal" eller "Sista minuten"-erbjudanden. Efter att ha lagt till rabatterad procentandel kommer du också att se visa det rabatterade priset. På mobilappen kommer vi att visa originalpris, rabatterat pris och rabatt i procent.',
        ansNineteen:
            'Efter att du har skapat kontot kan du skriva ut QR-koden som du hittar på drop down menyn eller på inställningssidan. Sätt upp QR-koden  så att kunden kan ladda ner appen.',
        ansTwenty:
            'Varje användare/patient kan ge en recension till kliniken. Recensionerna ges på mobilappen.',

        invalid: 'Ogiltigt format',
        setting: 'Klinikinställningar',
        postAdd: 'Postadress',
        eAddress: 'Din e-postadress',
        image: 'Din Bild ',
        imageDisplay: 'Detta kommer att visas på din profil.',
        about: 'Om',
        Cli: 'Kliniken',
        cliTime: 'Klinikens Tider',
        mon: 'MÅN',
        tue: 'TIS',
        wed: 'ONS',
        thu: 'TOR',
        fri: 'FRI',
        sat: 'Lör',
        sun: 'Sön',
        dealClick: 'Klicka på Deal eller Sista minuten-bokning i menyn',
        dealKey: 'Klicka på Skapa erbjudande eller Skapa sista minuten bokning',
        dealSubKey:
            'Fyll i informationen om erbjudandet och sista minuten bokning  ',
        step1: 'Steg 1',
        step2: 'Steg 2',
        step3: 'Steg 3',
        cliPat: 'Mina klinikpatienter',
        noOfClinic: 'Antal kliniker',
        totalPat: 'Totalt antal patienter ',
        qr: 'Din QR-Kod',
        down: 'LADDA NER',
        save: 'SPARA',
        error: 'Endast alfanumeriska är tillåtna',
        bookCapital: 'Sista Minuten Bokningar',
        timeTo: 'till',
        noTime: 'Inga tidsbegränsningar',
        autoCompleteError: 'Välj ett alternativ från listan',
        items: 'poster',
        total: 'Totala ',
        login: 'Inloggningen lyckades',
        lastSuccess: 'Sista minuten bokningen skapades.',
        emailVerify: 'E-posten har verifierats.',
        forgetPas: 'E-post har skickats',
        resetSuccess: 'Lösenordet har återställts.',
        myDeal: 'Mina Erbjudanden',
        myLastMinDeal: 'Mina Sista Minuten-Bokningar',
        verSign: 'Kontoverifieringskoden har skickats till din e-post.',
        updateSuccess: 'Affären har uppdaterats framgångsrikt.',
        updateLastminSuccess:
            'Sista minuten-bokningen har uppdaterats framgångsrikt.',
        createSuccess: 'Affären skapades framgångsrikt.',
        invalidCredentials: 'Ogiltiga uppgifter.',
        updateClinic: 'Din data har uppdaterats framgångsrikt.',
        maxNumberOfChar: 'MAX ANTAL TECKEN ÄR 500.',
        updateClinicSetting:
            'Uppdatera ditt foto och dina personliga uppgifter här',
        faqs: 'Vanliga frågor',
        readMore: 'Läs mer',
        attachUserWith: 'Lägg till användare med',
        phoneNotAvailable: 'Telefonnumret är inte tillgängligt',
        dashboardAboutPlaceholder:
            'Uppdatera din information om din mottagning under inställningar.',
        imageUploadingError:
            'Den valda bilden är för stor. Vänligen välj en bild som är 2MB eller mindre i storlek.',
        cardBooked: 'Bokat',
        previewName: 'Namn',
        previewAbout: 'Om Erbjudanden',
        previewTiming: 'Tider För rbjudanden',
        previewLocation: 'Platskarta',
        previewAddress: 'Klinikens Adress',
        previewBookAppointment: 'Boka Tid',
        scan: 'Skanna',
        qrCode: 'QR-kod',
        successContactUs: 'Meddelandet har skickats.',
        notifyAttachUser: '*Ange mobilkonto-ID',
        printQr: 'Skriv ut QR',
        notifyLastminDealDiscount: '*Pris med rabatt blir',
        notifyLastminDealCount: '*Skapa upp till 10 sista minuten-bokningar',
        iAgree: 'Jag går med på',
        termsAndCondition: 'Allmänna Villkor',
        termsOfUses: 'Användarvillkor (”Villkor”)',
        termPara1:
            'Vänligen läs dessa användarvillkor ("Villkoren", "Användarvillkoren") noggrant innan du använder Gnizzel (eller Gnizzle) plattformen ("Onlineplattformen") som drivs av Woodbury AB, Sverige organisations nummer 559108-3901 ("oss", "vi", eller "vår").',
        termPara2:
            'Din tillgång till och användning av denna online plattform är villkorad av att du accepterar och följer dessa villkor. Dessa villkor gäller för alla säljare, deltagare, besökare, sponsorer och andra som får tillgång till eller använder online plattformen.',
        termPara3:
            'Genom att komma åt eller använda online plattformen samtycker du till att vara bunden av dessa villkor. Om du inte håller med om någon del av villkoren kan du inte komma åt online plattformen.',
        overviewTerm: 'Översikt',
        overviewPara1:
            'Gnizzel tillhandahåller en online plattforms tjänst där konsumenter och industriföretag kan undersöka, hitta och handla olika tandvårdstjänster som består av tjänster som sträcker sig från olika leverantörer och mottagningar, brett utbud av priser för att svara på köparens behov.',
        overviewPara2:
            'Huvudsyftet med denna online plattform är att ge alla användare av hälso- och sjukvårdsindustrin enkel tillgång till en mängd olika tandvårdstjänster och liknande anläggningar. Tjänsten som visas på plattformen introduceras av tredje parter som har en benägenhet att göra handel inom dental och tandvård industrin.',
        accountsRegistration: 'Konton och Registrering',
        acceptContinue: 'Acceptera & Fortsätt',
        deny: 'Förneka',
        updateAndContinue: 'Uppdatera och fortsätt',
        changePhoneNumber: 'Byt telefonnummer',
        continueWithExist: 'Fortsätt med befintliga data',
        accountsRegistrationTerm: `Du kan använda del av tjänsten utan att registrera dig, men för fullständig användning tjänsterna på online plattformen måste du registrera dig för ett konto. När du registrerar dig för ett konto kan du bli tvungen att förse oss med viss information om dig själv, såsom din e-postadress eller annan kontaktinformation. Du samtycker till att informationen du lämnar till oss är korrekt och att du alltid kommer att hålla den korrekt och uppdaterad. När du registrerar dig kommer du att bli ombedd att skapa ett lösenord. Du är ensam ansvarig för att upprätthålla sekretessen för ditt konto och lösenord, och du accepterar ansvaret för alla aktiviteter som sker under ditt konto. Om du har anledning att tro att ditt konto inte längre är säkert, måste du omedelbart meddela oss på `,
        usage: 'ANVÄNDANDE',
        usagePara1:
            'Användningen av online plattformen är begränsad till personer över 18 år och som är i en position så att de är fullt kapabla och kompetenta att ingå villkoren, skyldigheterna, bekräftelserna, utfästelserna och garantierna som anges i dessa Villkor och att följa och följa dessa Allmänna villkor.',
        usagePara2:
            'Du är ensam ansvarig för att skydda sekretessen för ditt användarnamn och lösenord och all aktivitet under kontot kommer att anses ha utförts av dig. Om du förser oss med falska och felaktiga detaljer har vi rätten att stänga av ditt konto och/eller avsluta detta avtal utan att hållas ansvariga för detsamma.',
        usagePara3:
            'Du är ensam ansvarig för den personliga informationen och andra relaterade/nödvändiga uppgifter som du tillhandahåller på online plattformen.',
        usagePara4:
            'Användarens godtycke rekommenderas när du skickar in och/eller laddar upp din erforderliga personliga information på den angivna säkra platsen på Online. Denna information ska inkludera användaruppgifter inklusive kontaktuppgifter.',
        usagePara5:
            'Om informationen som du tillhandahåller är falsk/bedräglig/felaktig eller vilseledande, förbehåller vi oss rätten att radera ditt konto utan att ta på oss något som helst ansvar.',
        usagePara6:
            'Gnizzel tar inget ansvars för den information som tillhandahålls av sjukvårdsindustrin eller tandläkare. Alla avtal om tidpunkter, behandling och missförhållanden är mellan användare och sjukvårdsbranschen eller tandläkare där Gnizzel inte kan hållas ansvarig.',
        usagePara7:
            'Användaren kan inte under några omständigheter hålla Gnizzel ansvarig för felaktig hantering av sjukvård eller tandläkare.',
        termination: 'Uppsägning',
        terminationPara1:
            'Vi kan avsluta eller stänga av åtkomsten till din tjänst omedelbart, utan föregående meddelande eller ansvar, oavsett anledning, inklusive utan begränsning om du bryter mot villkoren.',
        terminationPara2:
            'Alla bestämmelser i villkoren som till sin natur bör överleva uppsägning ska överleva uppsägning, inklusive, utan begränsning, ägandebestämmelser, garantifriskrivningar, skadestånd och begränsningsansvar.',
        terminationPara3: `Följande villkor reglerar alla transaktioner med avseende på åtkomst och användning av Gnizzel via online plattformen och/eller genom dess auktoriserade tredjepartswebbplatser. Dessa villkor och villkor utgör en integrerad del av Gnizzel och allt som är direkt relaterat till Gnizzel inklusive och obegränsat till alla kampanjplattformar, t.ex. utskrifter, digitala, online och andra relaterade plattformar.`,
        terminationPara4: `Användarna uppmanas att göra en oberoende bedömning av tjänstens kvalitet, ändamålsenlighet, noggrannhet, användbarhet och/eller lämplighet innan de fattar något beslut.`,
        terminationPara5:
            'För syftet med dessa användarvillkor, varhelst sammanhanget kräver det:',
        child1TerminationPara5:
            '"användare" / "du" / "din" hänvisar härefter till användaren både registrerad och oregistrerad som använder och/eller surfar genom online plattformen och/eller genom dess auktoriserade tredjepartswebbplatser.',
        child2TerminationPara5:
            '"Gnizzel" eller "tjänster" avser hädanefter online plattformen som använder ljud, visuell och proprietär datakommunikation via online plattformen.',
        terminationPara6:
            'Genom att använda, surfa, få tillgång till online plattformen är du bunden av dessa villkor och alla andra villkor eller policyer (som kan ändras från tid till annan) som beslutats och publicerats av Gnizzel. Alla tjänster som tillhandahålls av online plattformen och som inte kräver registrering fritar dig inte från det avtalsförhållande som fastställs i dessa användarvillkor.',
        terminationPara7:
            'Användarvillkoren häri bildar ett avtal. Vänligen läs dessa användarvillkor noggrant och om du inte accepterar några villkor, fortsätt inte vidare. Genom att klicka på "Jag godkänner" (markera i boxen) och gå vidare bekräftar du att du läst igenom, förstått och accepterat ovannämnda villkor. Du måste godkänna ovanstående villkor för att kunna använda tjänsterna via webbplatsen. Om du har några frågor eller behöver något förtydligande eller hjälp, vänligen kontakta oss på ',
        terminationPara8:
            'Gnizzel kan när som helst, utan föregående meddelande till sina användare, ändra dessa användarvillkor. Läs igenom den senaste versionen av användarvillkoren innan du fortsätter att använda Gnizzels tjänst. Om du fortsätter att använda tjänsten ska det anses att du godkänner och följer de ändrade villkoren.',
        usingThePlatform: 'Använda plattformen',
        usingThePlatformPara1:
            'Tillgång till plattformen är tillåten på tillfällig basis och vi förbehåller oss rätten att dra tillbaka eller ändra tjänsten vi tillhandahåller på plattformen eller någon del av plattformen utan föregående meddelande. Vi kommer inte att hållas ansvariga eller ansvariga om plattformen av någon anledning är otillgänglig när som helst eller under någon period.',
        usingThePlatformPara2:
            'Informationen, tjänsterna på plattformen är inte avsedda att utgöra ett råd om vilket beslut som ska tas av användaren. Som sådan frånsäger vi oss allt ansvar och ansvar som härrör från all tillit som placeras på sådan information och material av någon besökare på plattformen, eller av någon person som kan bli informerad om något av dess innehåll.',
        usingThePlatformPara3:
            'Du kan se (och, i förekommande fall, lyssna på) innehållet och applikationerna som finns tillgängliga på plattformen för din egen privata icke-kommersiellt bruk. Du får inte använda eller tillåta andra att komma åt eller använda, hela eller någon del av vår plattform eller dess tillgängliga innehåll och/eller applikationer på den för kommersiella ändamål utan vårt tillstånd. Användning av hela eller delar av vår plattform eller innehållet och/eller applikationerna på den för kommersiella ändamål ska omfattas av separata villkor och kan bli föremål för avgifter.',
        usingThePlatformPara4:
            'Du kan ibland skriva ut enskilda webbsidor från plattformen för ditt privata icke-kommersiella bruk, förutsatt att sådan utskrift inte är omfattande eller systematisk och att våra varumärken, upphovsrättsmeddelanden och varumärkesmeddelanden inte tas bort.',
        usingThePlatformPara5:
            'Från tid till annan kan vi begränsa åtkomsten till vissa delar av online plattformen eller hela plattformen till användare som har registrerat sig hos oss. Registrerade användare kan anpassa några av sina produktfunktioner för att skapa personliga områden inom plattformen för privat icke-kommersiell/kommersiell användning. Användare får inte på annat sätt ändra, anpassa reverse engineering någon del av plattformen.',
        usingThePlatformPara6:
            'Om inte annat anges i dessa användarvillkor får du inte (varken direkt eller indirekt):',
        child1usingThePlatformPara6:
            'distribuera, överföra,  sälja eller erbjuda att sälja eller på annat sätt göra tillgänglig hela eller någon del av plattformen eller något innehåll, filer, flöden eller data från plattformen, oavsett om det är offentligt tillgängligt eller inte; eller',
        child2usingThePlatformPara6:
            'kopiera, ladda ner eller lagra innehåll, filer, flöden eller data från plattformen, oavsett om det är offentligt tillgängligt eller inte, för att skapa eller fylla i en databas eller publicering av något slag, förutsatt att',
        child3usingThePlatformPara6:
            'Du får endast spela upp video- eller ljudfiler med hjälp av mediaspelaren på plattformen eller på webbplatsen för en av våra licenstagare som visar sådant material med vårt tillstånd',
        child4usingThePlatformPara6:
            'Användare bör vara medvetna om att innehåll och resurser kan tas bort från plattformen med eller utan förvarning när som helst och vi tar inget ansvar eller ansvar för användarens förtroende för den fortsatta tillgängligheten av innehåll eller resurser på plattformen',
        dataProtection: 'Dataskydd och användning',
        dataProtectionPara1:
            'Gnizzel ska, genom att tillhandahålla den plattform som anges i dessa användarvillkor, följa och följa alla tillämpliga lagar och dess integritetspolicy relaterade till integritet och säkerhet för eventuella personuppgifter (“Data”) och sådana dokument kan komma att ändras från tid till annan till tid av Gnizzel efter eget gottfinnande.',
        dataProtectionPara2:
            'Användaren samtycker till att behandla data i enlighet med dessa användarvillkor, integritetspolicyn och alla lagliga instruktioner som rimligen ges av Gnizzel från tid till annan.',
        dataProtectionPara3:
            'Gnizzel ska vidta lämpliga tekniska och organisatoriska åtgärder mot otillåten eller olaglig behandling av uppgifterna eller dess oavsiktliga förlust, förstörelse eller skada.',
        dataProtectionPara4:
            'I samband med tillhandahållandet av online plattformen samlar Gnizzel in data om användaren. Dessa data används för att leverera och förbättra plattformen och de tjänster som anges i dessa användarvillkor. Dessutom, där dessa data är, eller kan omvandlas till, anonymiserade data, kan Gnizzel använda sådan anonymiserad data för andra ändamål inklusive men inte begränsat till analys och benchmarking. För att undvika tvivel kommer Gnizzel inte att avslöja några uppgifter som erhållits under dessa Användarvillkor som inte är anonymiserade till någon tredje part, som krävs enligt lag, eller till parter som är involverade i uppfyllandet av Gnizzels skyldigheter enligt dessa Användarvillkor.',
        ownership: 'Äganderätt; Äganderätt',
        ownershipPara1:
            'Onlineplattformen ägs och drivs av Woodbury AB. De visuella gränssnitten, grafik, design, kompilering, information, data, datorkoder (inklusive källkod eller objektkod), service och dess specifikationer, programvara, tjänster och alla andra delar av onlineplattformen ("Material") som tillhandahålls av Gnizzel är skyddas av immateriella rättigheter och andra lagar. Allt material som ingår på onlineplattformen tillhör Gnizzels eller våra tredjepartslicensgivare. Förutom vad som uttryckligen godkänts av Woodbury AB, får du inte använda materialet. Woodbury AB förbehåller sig alla rättigheter till materialet som inte uttryckligen ges i dessa villkor. Woodbury AB äger och i tillämpliga fall licensen för webbplatsen och onlineplattformen samt den immateriella egendomen som finns på den inklusive alla program, processer, design, mjukvara, teknologier, varumärken, handelsnamn, uppfinningar och material däri. Du ska inte använda innehållet som är tillgängligt på webbplatsen eller onlineplattformen utan vårt skriftliga tillstånd i förväg.',
        specialError:
            'Endast alfanumeriska och - / % är tillåtna och längden får inte vara mer än 25 tecken',
        clickHere: 'Klicka Här',
        pricePlanPageHeading: 'Prisplan För Användning Av Gnizzel Tjänsterna',
        pricePlanPageDescription:
            'Marknadsplatsen Gnizzel är marknads- och säljkanal för tandläkare mottagningar. Öka eran omsättning och låt nya kunder hitta till er genom att synas på Gnizzel.',
        priceCardSmallHeading: 'Liten',
        priceCardMediumHeading: 'Mellan',
        priceCardLargeHeading: 'Stor',
        priceCardSmallPrice: '599',
        priceCardMediumPrice: '999',
        priceCardLargePrice: '1399',
        priceCardSmallDescription:
            'Liten prisplan är för mottagningar med max 2 tandläkare',
        priceCardMediumDescription:
            'Mellan prisplan är för mottagningar med 3-5 tandläkare',
        priceCardLargeDescription:
            'Stor prisplan är för mottagningar med 6 eller fler tandläkare',
        whatsIncluded: 'Vad ingår',
        priceCardSmallFeatureOne:
            'För mindre mottagningar med max 2 tandläkare',
        priceCardSmallFeatureTwo:
            'Användning av Deals funktionaliteten vilket innebär att mottagningen får ha obegränsade erbjudanden på Gnizzel',
        priceCardSmallFeatureThree:
            'Användning av Sista Minuten funktionaliteten vilket innebär att mottagningen får ha obegränsade Sista Minuten tider på Gnizzel',
        priceCardSmallFeatureFour:
            'Unik QR kod att skriva ut för mottagningen så patienter att ladda ner Gnizzel mobil appen gratis',

        priceCardMediumFeatureOne:
            'För medelstora mottagningar med 3 till 5 tandläkare',
        priceCardMediumFeatureTwo:
            'Användning av Deals funktionaliteten vilket innebär att mottagningen får ha obegränsade erbjudanden på Gnizzel',
        priceCardMediumFeatureThree:
            'Användning av Sista Minuten funktionaliteten vilket innebär att mottagningen får ha obegränsade Sista Minuten tider på Gnizzel',
        priceCardMediumFeatureFour:
            'Unik QR kod att skriva ut för mottagningen så patienter att ladda ner Gnizzel mobil appen gratis',

        priceCardLargeFeatureOne:
            'För stora mottagningar med 6 eller fler tandläkare',
        priceCardLargeFeatureTwo:
            'Användning av Deals funktionaliteten vilket innebär att mottagningen får ha obegränsade erbjudanden på Gnizzel',
        priceCardLargeFeatureThree:
            'Användning av Sista Minuten funktionaliteten vilket innebär att mottagningen får ha obegränsade Sista Minuten tider på Gnizzel',
        priceCardLargeFeatureFour:
            'Unik QR kod att skriva ut för mottagningen så patienter att ladda ner Gnizzel mobil appen gratis',
        month: 'Månaden',
        uploadImage: 'Ladda upp bild',
        pricingButton: 'Prova Gnizzel 3 Månader Gratis',
        gnizzelServices: 'Gnizzel tjänsterna',
        PricePlan: 'Prisplan för användning av ',
        clinicJoinGnizzel: 'Varför ska din klinik vara med på Gnizzel?',
        clinicJoinGnizzelHeadingOne:
            'Öka synligheten och inflödet av nya patienter!',
        clinicJoinGnizzelHeadingTwo: 'Nå ut till fler patienter!',
        clinicJoinGnizzelHeadingThree: 'Bidra till att förbättra tandhälsan!',
        clinicJoinGnizzelParagraphOne:
            'På Gnizzel kan fler patienter hitta er mottagning och era tillgängliga tider. Det bidrar till ökat inflöde av patienter till eran mottagning.',
        clinicJoinGnizzelParagraphTwo:
            'Genom Gnizzel som är en marknadsplats kan ni nå ut till gamla och nya patienter med vår mobila app.',
        clinicJoinGnizzelParagraphThree:
            'I takt med att fler blir allt äldre och behöver hjälp och stöd med sin dagliga omvårdnad ökar även behovet av stöd vid munvård för en tillfredsställande munhälsa.',
        customerOfGnizzel: 'Det här får du som kund hos Gnizzel',
        customerOfGnizzelHeadingOne:
            'Skapa egna erbjudanden för att locka nya kunder till kliniken',
        customerOfGnizzelHeadingTwo:
            'Använd vår Sista Minuten tjänst för att fylla era lediga tider',
        customerOfGnizzelHeadingThree: 'Ha direkt kontakt med patienten',
        customerOfGnizzelParagraphOne:
            'Skapa olika erbjudande för att locka nya eller existerande kunder. Alla erbjudanden kommer ut på Gnizzels mobil app.',
        customerOfGnizzelParagraphTwo:
            'Har ni några lediga tider så kan ni använda er av "Sista Minuten" tjänsten för att fylla upp era bokningar.',
        customerOfGnizzelParagraphThree:
            'Patienter kan ringa er direkt för att boka en tid.',
        termPayment: 'Betalning ',
        termPaymentSubscription: 'Abonnemangsvillkor och fakturering',
        termPaymentSubscriptionPara1: `Abonnemanget börjar löpa från och med datumet som registrering sker på tjänsten och löper tills det avslutas vid uppsägning elller avslutad konto. Uppsägningstiden är tre månader.`,
        termPaymentSubscriptionPara2: `Den första faktureringsperioden löper från beställningsdatumet till den sista dagen i innevarande månad. Fakturering sker i förskott, om inte annat anges i ett separat avtal mellan parterna eller annars framgår av villkor rörande den specifika tjänsten.`,
        termPaymentSubscriptionPara3: `Tjänsten görs tillgänglig via nätet och utgör mjukvara som tjänst. Kunden förvärvar inte Tjänsten eller en kopia eller del av den och ges inte en licens att tillgodogöra sig Tjänsten på annat sätt än som mjukvara som tjänst.`,
        termPaymentPrice: 'Priser och Betalningsvillkor',
        termPaymentPricePara1: `Betalning ska ske senast 10 dagar efter fakturadatum eller vid kort betalning sker dragning av beloppet senast slut av innevarande månad. Vid ej betald faktura på fakturadatum har Gnizzel rätt att blockera tjänsten för Kunden. Tjänsten kommer att återaktiveras när betalning skett om inte Gnizzel har annullerat abonnemanget.`,
        termPaymentPricePara2: `Aktuella priser och abonnemangsformer kan komma att förändras. Kunden ska informeras om förändringen senast en månad innan den träder i kraft förutom när det är rabatterade priser eller kampanjer, då har Gnizzel rätt att göra ändringarna omedelbart. Alla priser anges exklusive mervärdesskatt.`,
        currency: 'SEK',
        company_address: 'Företagsadress',
    },
};

export default sew;
