import React, { useEffect, useState } from 'react';
import './ClinicUser.css';
import { ClinicInput } from '../../commonComponents';
import logo from '../../../assets/images/logoWithText.png';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row } from 'antd';

const ClinicUser = (props) => {
    const { email, profile, city, company_name, company_address } = props.data;
    const { t } = useTranslation();
    const [avatar, setAvatar] = useState(profile);

    useEffect(() => {
        setAvatar(profile);
    }, [profile]);
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '.5rem',
                }}
            >
                <div className="logoContainer">
                    <img src={logo} alt="Gnizzel Logo" />
                </div>
            </div>
            {/* <img
                src={avatar}
                alt={email}
                width={100}
                height={100}
                style={{ borderRadius: '100%' }}
                onError={() => {
                    setAvatar('');
                }}
            /> */}
            <h1 style={{ textAlign: 'center' }}>{t('welcomeToGnizzel')}</h1>
            <p style={{ textAlign: 'center' }}>
                {t('welcomeToGnizzelDescription')}
            </p>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '.5rem',
                }}
            ></div>

            <h2 style={{ textAlign: 'center' }}>{t('clinicDetail')}</h2>
            <Form
                layout="vertical"
                initialValues={props.data}
                autoComplete="off"
            >
                <Row
                    gutter={[16, 16]}
                    justify={'center'}
                    className="signupCard"
                >
                    <Col span={24}>
                        <ClinicInput
                            type={'text'}
                            name={'company_name'}
                            Value={company_name}
                            placeholder={`${t('clicName')}`}
                            label={t('clicName')}
                            isReadOnly
                        />
                    </Col>
                    <Col span={24}>
                        <ClinicInput
                            placeholder={`${t('city')}`}
                            label={t('city')}
                            type="text"
                            name="city"
                            Value={city}
                            isReadOnly
                        />
                    </Col>
                    <Col span={24}>
                        <ClinicInput
                            placeholder={`${t('company_address')}`}
                            label={t('company_address')}
                            type="text"
                            name="company_address"
                            Value={company_address}
                            isReadOnly
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ClinicUser;
