import { Form, Input, Select, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import './ClinicInput.css';
import { useRef } from 'react';
export function ClinicInput({
    label,
    placeholder,
    name,
    Value,
    inputValue,
    type,
    textAreaMaxRow = 2,
    height,
    width = 100,
    onChange,
    disabled = false,
    isRequired,
    requiredErrorMessage,
    isRequiredType,
    isRequiredTypeMessgae,
    maxLength,
    borderWidth = 1,
    inputData,
    onKeyDown,
    max,
    min = 0,
    isReadOnly = false,
}) {
    const { TextArea } = Input;
    const numberInput = useRef();

    return (
        <div className="test">
            {type === 'text' ? (
                <Form.Item
                    name={name}
                    label={label}
                    style={{ width: `${width}%` }}
                    initialValue={Value}
                    rules={[
                        {
                            required: isRequired,
                            message: `${requiredErrorMessage}`,
                        },
                        {
                            type: `${isRequiredType}`,
                            message: `${isRequiredTypeMessgae}`,
                        },
                    ]}
                >
                    <Input
                        placeholder={placeholder}
                        name={name}
                        value={Value}
                        onChange={onChange}
                        style={{ height: `${height}px` }}
                        className="inputStyle"
                        maxLength={maxLength}
                        onKeyDown={onKeyDown}
                        disabled={disabled}
                        readOnly={isReadOnly}
                    />
                </Form.Item>
            ) : type === 'password' ? (
                <Form.Item
                    label={label}
                    name={name}
                    style={{ width: `${width}%` }}
                    rules={[
                        {
                            required: isRequired,
                            message: `${requiredErrorMessage}`,
                        },
                        {
                            type: 'password',
                            min: 6,
                            max: 8,
                            message: `${isRequiredTypeMessgae}`,
                        },
                    ]}
                >
                    <Input.Password
                        placeholder={placeholder}
                        name={name}
                        defaultValue={Value}
                        onChange={onChange}
                        style={{ height: `${height}px` }}
                        className="inputStyle passwordInput"
                    />
                </Form.Item>
            ) : type === 'textarea' ? (
                <Form.Item
                    label={label}
                    name={name}
                    style={{ width: `${width}%`, font: '16px' }}
                    rules={[
                        {
                            required: isRequired,
                            message: `${requiredErrorMessage}`,
                        },
                    ]}
                >
                    <TextArea
                        placeholder={placeholder}
                        name={name}
                        autoSize={{
                            minRows: textAreaMaxRow,
                            maxRows: 10,
                        }}
                        style={{
                            width: `${width ? width : 100}%`,
                            font: '16px',
                            border: `${borderWidth}px solid hsl(220, 35%, 93%)`,
                        }}
                        maxLength={maxLength ? maxLength : 250}
                        defaultValue={Value}
                        className="textareaStyle"
                        onChange={onChange}
                    />
                </Form.Item>
            ) : type === 'number' ? (
                <Form.Item
                    label={label}
                    name={name}
                    style={{ width: `${width}%` }}
                    rules={[
                        {
                            required: isRequired,
                            message: `${requiredErrorMessage}`,
                        },
                    ]}
                >
                    <Input
                        placeholder={placeholder}
                        name={name}
                        value={Value}
                        ref={numberInput}
                        id={name}
                        aria-valuenow={Value}
                        disabled={disabled}
                        type="number"
                        controls={false}
                        onChange={onChange}
                        style={{ height: `${height}px` }}
                        className="inputStyle"
                        autoFocus
                        max={max}
                        maxLength={8}
                        min={min}
                    />
                </Form.Item>
            ) : type === 'select' ? (
                <Form.Item
                    name={name}
                    label={label}
                    className="selectContainer"
                >
                    <Select
                        defaultValue="1"
                        style={{ width: '100%' }}
                        onChange={onChange}
                        value={Value}
                        options={[
                            {
                                value: '1',
                                label: '1',
                            },
                            {
                                value: '2',
                                label: '5',
                            },
                            {
                                value: '3',
                                label: '10',
                            },
                        ]}
                    />
                </Form.Item>
            ) : type === 'special' ? (
                <Input
                    placeholder={placeholder}
                    name={name}
                    value={Value}
                    type="text"
                    onChange={onChange}
                    style={{ height: `${height}px` }}
                    className="inputStyle"
                    maxLength={maxLength}
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                />
            ) : (
                ''
            )}
        </div>
    );
}

ClinicInput.defaultProps = {
    label: '',
    placeholder: '',
    name: '',
    Value: '',
    type: 'text',
    inputValue: 0,
    textAreaMaxRow: 0,
    height: 44,
    width: 100,
    onChange: () => {},
    isRequired: false,
    requiredErrorMessage: '',
    isRequiredType: '',
    isRequiredTypeMessgae: '',
};

ClinicInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    Value: PropTypes.string,
    inputValue: PropTypes.number,
    type: PropTypes.string,
    textAreaMaxRow: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    onChange: PropTypes.func,
    isRequired: PropTypes.bool,
    requiredErrorMessage: PropTypes.string,
    isRequiredType: PropTypes.string,
    isRequiredTypeMessgae: PropTypes.string,
    maxLength: PropTypes.number,
};
